export function initAccordions() {
    window.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    const sections = document.querySelectorAll('section.accordions');
    for (const section of sections) {
        const accordions = section.querySelectorAll('.accordions .accordion');
        for (const accordion of accordions) {
            const button = accordion.querySelector('.accordion-button');
            if (button) {
                button.addEventListener('click', function(e){
                    accordion.classList.toggle('open');
                    if (accordion.classList.contains('open')) {
                        const h = accordion.querySelector('.accordion-collapse-content')?.clientHeight;
                        accordion.querySelector('.accordion-collapse')?.style.setProperty('--h', h + 'px');
                    }
                });
            }
        }
    }
}