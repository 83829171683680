export function initSliders() {
    window.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function initScrollPaginationArrows(sliderSection) {
    const scrollWindow = sliderSection.querySelector('.images-window');
    const leftArrow = sliderSection.querySelector('.arrow.prev');
    const rightArrow = sliderSection.querySelector('.arrow.next');
    const currentNumberElem = sliderSection.querySelector('.pagination .current');
    const images = [...sliderSection.querySelectorAll('.images-wrapper > .image')];
    const imageWidths = images.map(elem => elem.offsetWidth + 24);
    const imageCumulativeWidths = [0, ...imageWidths.reduce((acc, curr, index) => [...acc, curr + (acc[index - 1] || 0)], [])];
    
    // scroll pagination
    scrollWindow.addEventListener('scroll', () => {
        const scrollPosition = scrollWindow.scrollLeft;
        let pos = imageCumulativeWidths.findLastIndex(e => e <= scrollPosition);
        if (scrollPosition + window.innerWidth + 1 > scrollWindow.scrollWidth) {
            pos ++;
        }
        pos = Math.min(pos, images.length - 1);
        currentNumberElem.innerText = (pos + 1);
        if (leftArrow) {
            if (pos === 0) {
                leftArrow.classList.add('inactive');
                leftArrow.tabindex = '-1';
            } else {
                leftArrow.classList.remove('inactive');
                leftArrow.tabindex = null;
            }
        }
        if (rightArrow) {
            if (pos === images.length - 1) {
                rightArrow.classList.add('inactive');
                rightArrow.tabindex = '-1';
            } else {
                rightArrow.classList.remove('inactive');
                rightArrow.tabindex = null;
            }
        }
    });

    // arrows
    if (leftArrow) {
        leftArrow.addEventListener('click', e => {
            e.preventDefault();
            const currentIdx = parseInt(currentNumberElem.innerText, 10);
            const newIdx = currentIdx - 1;
            if (newIdx < 0) {
                return;
            }
            scrollWindow.scrollTo({ left: imageCumulativeWidths[newIdx - 1], behavior: 'smooth' });
        });
    }
    if (rightArrow) {
        rightArrow.addEventListener('click', e => {
            e.preventDefault();
            const currentIdx = parseInt(currentNumberElem.innerText, 10);
            const newIdx = currentIdx + 1;
            if (newIdx > imageWidths.length) {
                return;
            }
            scrollWindow.scrollTo({ left: imageCumulativeWidths[newIdx - 1], behavior: 'smooth' });
        });
    }
    
    // hotspots
    const hotspots = sliderSection.querySelectorAll('.hotspot a');
    for (const hotspot of hotspots) {
        hotspot.addEventListener('click', e => e.preventDefault());
    }
}

function init() {
    const sliderSections = document.querySelectorAll('section.slider');
    for (const sliderSection of sliderSections) {
        initScrollPaginationArrows(sliderSection);
    }
}