export function initFeatureCards() {
    window.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    const cardSections = document.querySelectorAll('section.feature-cards');
    for (const cardSection of cardSections) {
        const cards = cardSection.querySelectorAll('.card');
        for (const card of cards) {
            card.addEventListener('mouseenter', function(){
                for (const c of cards) {
                    c.classList.remove('hovered');
                }
                card.classList.add('hovered');
            });
        }
    }
}