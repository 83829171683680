export function initFooterMenuCollapse() {
    window.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    const footerMobileLinks = document.querySelectorAll('#footer .mobile-menu a.block-title');
    for (const footerMobileLink of footerMobileLinks) {
        footerMobileLink.addEventListener('click', function(e){
            e.preventDefault();
            const h = this.nextElementSibling.querySelector('ul').clientHeight;
            this.nextElementSibling.style.setProperty('--menu-height', h + 'px');
            this.classList.toggle('open');
            const links = this.nextElementSibling.querySelectorAll('a');
            if (this.classList.contains('open')) {
                this.nextElementSibling.ariaExpanded = 'true';
                for (const link of links) {
                    link.tabIndex = null;
                }
            } else {
                this.nextElementSibling.ariaExpanded = 'false';
                for (const link of links) {
                    link.tabIndex = -1;
                }
            }
        });
    }
}