export function initSpecificationsTabs() {
    window.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    const tabButtons = document.querySelectorAll('section.specifications-tabs .tabs-wrapper button.tab');
    for (const tabButton of tabButtons) {
        tabButton.addEventListener('click', function(e) {
            e.preventDefault();
            const newId = this.dataset.id;
            const allSiblings = this.parentElement.querySelectorAll('button.tab');
            for (const s of allSiblings) {
                s.classList.remove('active');
            }
            this.classList.add('active');
            const section = this.closest('section');
            const tabContents = section.querySelectorAll('.tab-content');
            for (const tabContent of tabContents) {
                const isNew = tabContent.dataset.id === newId;
                tabContent.classList.toggle('hidden', ! isNew);
                tabContent.classList.toggle('block', isNew);
            }
        });
    }
}