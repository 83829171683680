export function initPopups() {
    window.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    const overlayContainer = document.querySelector('.popup-overlay');
    if (! overlayContainer) {
        return;
    }
    const popup = overlayContainer.querySelector('.popup');
    if (! popup) {
        return;
    }
    let storageEngine = null;
    if (popup.dataset.mode === 'session') {
        storageEngine = sessionStorage;
    } else if (popup.dataset.mode === 'local') {
        storageEngine = localStorage;
    }
    if (! storageEngine) {
        return;
    }
    if (storageEngine.getItem('popup-seen-' + popup.dataset.hash) !== '1') {
        setTimeout(() => {
            showPopup(popup, overlayContainer, storageEngine);
        }, parseInt(popup.dataset.appear));
    }
    const closeButton = popup.querySelector('.close');
    if (closeButton) {
        closeButton.addEventListener('click', e => { e.preventDefault(); closePopup(popup, overlayContainer); });
    }
}

function closePopup(popup, overlayContainer) {
    overlayContainer.classList.add('hidden', 'opacity-0');
    popup.classList.add('hidden');
    document.body.classList.remove('overflow-hidden');
}

function showPopup(popup, overlayContainer, storageEngine) {
    storageEngine.setItem('popup-seen-' + popup.dataset.hash, '1');

    overlayContainer.classList.remove('hidden');
    popup.classList.remove('hidden');
    document.body.classList.add('overflow-hidden');
    setTimeout(() => {
        overlayContainer.classList.remove('opacity-0');
    }, 10);
}