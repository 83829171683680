// Helper for fetching CSRF token
export async function getSessionInfo() {
    const response = await fetch('/actions/users/session-info', {
        headers: {
            'Accept': 'application/json',
        },
    });
    return response.json();
}

// Helper for creating POST requests to backend
export async function createPostRequest(url, payload, contentType = 'application/json') {
    try {
        const session = await getSessionInfo();
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': contentType,
                'Accept': 'application/json',
                'X-CSRF-Token' : session.csrfTokenValue,
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: JSON.stringify(payload)
        });
        return {
            success: true,
            response: await response.json()
        }
    } catch (e) {
        return {
            success: false,
            response: e
        }
    }
}