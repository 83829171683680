export function initOffcanvas() {
    window.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    const offcanvasElements = document.querySelectorAll('.offcanvas');
    for (const offcanvasElement of offcanvasElements) {
        offcanvasElement.addEventListener('offcanvas/open', function(){
            this.classList.add('showing');
            setTimeout(() => {
                this.classList.add('show');
                this.classList.remove('showing');
            }, 300);
            document.documentElement.style.overflow = 'hidden';
            document.body.style.overflow = 'hidden';
        });
        offcanvasElement.addEventListener('offcanvas/close', function(){
            this.classList.add('hiding');
            setTimeout(() => {
                this.classList.remove('hiding', 'show');
            }, 300);
            document.documentElement.style.overflow = null;
            document.body.style.overflow = null;
        });
    }
}