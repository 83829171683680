export function initHomeShowcase() {
    window.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    const sections = document.querySelectorAll('section.home-showcase');
    for (const section of sections) {
        const homeLinks = section.querySelectorAll('a.home-type');
        for (const homeLink of homeLinks) {
            homeLink.addEventListener('click', e => {
                e.preventDefault();
                const link = e.target.closest('a.home-type');
                const id = link.dataset.id;

                if (link.classList.contains('active')) {
                    return;
                }

                const oldDataWrappers = document.querySelectorAll('.home-data-wrapper.show, .home-data-wrapper.will-show');
                for (const oldDataWrapper of oldDataWrappers) {
                    oldDataWrapper.classList.remove('show', 'will-show');
                    setTimeout(() => {
                        oldDataWrapper.classList.add('hidden');
                    }, 300)
                }
                const newDataWrapper = document.querySelector(`.home-data-wrapper[data-id="${id}"]`);
                if (newDataWrapper) {
                    newDataWrapper.classList.add('will-show');
                    setTimeout(() => {
                        newDataWrapper.classList.remove('will-show', 'hidden');
                        setTimeout(() => {
                            newDataWrapper.classList.add('show');
                            if (window.innerWidth < 768) {
                                newDataWrapper.scrollIntoView({behavior: 'smooth'});
                            }
                        }, 10);
                    }, 300);
                }

                for (const l of homeLinks) {
                    l.classList.remove('active');
                }
                link.classList.add('active');
            });
        }

        const mobileDetailsButtons = section.querySelectorAll('a.mobile-details-button');
        for (const mobileDetailsButton of mobileDetailsButtons) {
            const offcanvasId = mobileDetailsButton.dataset.offcanvasId;
            const offcanvasElement = document.getElementById(offcanvasId);
            mobileDetailsButton.addEventListener('click', (e) => {
                e.preventDefault();
                if (offcanvasElement) {
                    offcanvasElement.dispatchEvent(new Event('offcanvas/open'));
                }
            });
            if (offcanvasElement) {
                const closeButton = offcanvasElement.querySelector('.offcanvas-header .close');
                if (closeButton) {
                    closeButton.addEventListener('click', e => {
                        e.preventDefault();
                        offcanvasElement.dispatchEvent(new Event('offcanvas/close'));
                    });
                }
            }
        }
    }
}