export function initVideos() {
    window.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    const videoSections = document.querySelectorAll('section.video');
    for (const videoSection of videoSections) {
        const playButton = videoSection.querySelector('.play');
        if (playButton) {
            playButton.addEventListener('click', e => {
                e.preventDefault();
                videoSection.querySelector('.image-poster').classList.add('hidden');
                videoSection.querySelector('video').play();
            });
        }
    }
}