export function initScrollToNextSectionLinks() {
    window.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    const scrollToNextLinks = document.querySelectorAll('.scroll-to-next-section a');
    for (const scrollToNextLink of scrollToNextLinks) {
        scrollToNextLink.addEventListener('click', e => {
            e.preventDefault();
            const thisSection = e.target.closest('section');
            const nextSection = thisSection.nextElementSibling;
            if (nextSection) {
                nextSection.scrollIntoView({ behavior: 'smooth' });
            }
        });
    }
}