export function initHeader() {
    window.addEventListener('DOMContentLoaded', () => {
        initMobileMenu();
        initMobileMenuDropdown();
    });
}

function initMobileMenu() {
    const menuOpenerButton = document.querySelector('#header .menu-opener');
    const menuCloseButton = document.querySelector('#mobile-menu .close');
    const menuOffcanvas = document.getElementById('mobile-menu');
    if (menuOpenerButton && menuOffcanvas) {
        menuOpenerButton.addEventListener('click', e => {
            e.preventDefault();
            menuOffcanvas.dispatchEvent(new Event('offcanvas/open'));
        });
    }
    if (menuCloseButton && menuOffcanvas) {
        menuCloseButton.addEventListener('click', e => {
            e.preventDefault();
            menuOffcanvas.dispatchEvent(new Event('offcanvas/close'));
        });
    }
}

function initMobileMenuDropdown() {
    const menuOpenLinks = document.querySelectorAll('#mobile-menu .menu a.menu-opener');
    for (const menuOpenLink of menuOpenLinks) {
        menuOpenLink.addEventListener('click', function(e){
            e.preventDefault();
            const h = this.nextElementSibling.querySelector('ul').clientHeight;
            this.nextElementSibling.style.setProperty('--menu-height', h + 'px');
            this.classList.toggle('open');
            const links = this.nextElementSibling.querySelectorAll('a');
            if (this.classList.contains('open')) {
                this.nextElementSibling.ariaExpanded = 'true';
            } else {
                this.nextElementSibling.ariaExpanded = 'false';
            }
        });
    }
}