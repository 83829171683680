import Swiper from 'swiper';
import {Navigation, Pagination} from "swiper/modules";

export function initHomeData() {
    window.addEventListener('DOMContentLoaded', () => {
        const homeDataSections = document.querySelectorAll('section.home-data');
        for (const homeDataSection of homeDataSections) {
            initCarousel(homeDataSection);
            initDataAccordion(homeDataSection);
        }
    });
}

function initCarousel(homeDataSection) {
    const swiperElement = homeDataSection.querySelector('.swiper');
    const swiper = new Swiper(swiperElement, {
        modules: [Navigation, Pagination],
        loop: true,
        spaceBetween: 16,
        pagination: {
            el: '.swiper-pagination',
            bulletActiveClass: 'active',
            bulletClass: 'bullet',
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
}

function initDataAccordion(homeDataSection) {
    const descriptionButton = homeDataSection.querySelector('.description-button');
    if (descriptionButton) {
        descriptionButton.addEventListener('click', e => {
            e.preventDefault();
            e.target.closest('.description-button').nextElementSibling.classList.toggle('open');
        });
    }
}