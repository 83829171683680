import {createPostRequest} from "./helpers";

export function initEmails() {
    window.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

async function init() {
    const leadContactForm = document.getElementById('lead-collection-form');
    
    if( leadContactForm ) {
        leadContactForm.addEventListener('submit', async (e) => {
            e.preventDefault();
            const response = await createPostRequest('/modulwood/email', Object.fromEntries(new FormData(e.target)) );
            if (response.response.redirect_to) {
                window.location.href = response.response.redirect_to;
            }
        });
    }
}